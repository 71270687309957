import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

Vue.config.productionTip = false;

let firebaseConfig;
if (store.state.environment === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyCiF5Wrz5FToBMeORbFdmU1uSFygicXZ10",
    authDomain: "gigalot-cloud.firebaseapp.com",
    databaseURL: "https://gigalot-cloud.firebaseio.com",
    projectId: "gigalot-cloud",
    storageBucket: "gigalot-cloud.appspot.com",
    messagingSenderId: "820134020112",
    appId: "1:820134020112:web:1eec0a176ae84fe6",
    measurementId: "G-6YLQFSPYE0"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCxvvbSahUACHC8z76pXKVQex9XCGB5iY4",
    authDomain: "gigalot-testing.firebaseapp.com",
    databaseURL: "https://gigalot-testing.firebaseio.com",
    projectId: "gigalot-testing",
    storageBucket: "gigalot-testing.appspot.com",
    messagingSenderId: "17723559284",
    appId: "1:17723559284:web:ab0989a9ee7eb346137021",
    measurementId: "G-CE00KGJ969"
  };
}

firebase.initializeApp(firebaseConfig);
firebase.analytics();

firebase.auth().onAuthStateChanged(function(user) {
  store.dispatch("user/firebaseOnAuthStateChanged", user);
});

store.commit("version");
store.commit("download/queryNamesAndObjectStores");

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
