

























import { Component, Vue } from "vue-property-decorator";
import wb from "./registerServiceWorker";

@Component
export default class App extends Vue {

  async created() {
    if (wb)
      wb.addEventListener("waiting", async () => {
        //Update is ready, waiting
        this.$store.commit("popup/displayYesNo", {
          message: "An update is available. Would you like to update?",
          yesAction: async () => {
            if (wb) {
              await wb.messageSW({ type: "SKIP_WAITING" });
              window.location.reload(true);
            } else throw Error("No wb to messageSW SKIP_WAITING");
          }
        });
      });
  }

  async mounted() {
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
  }

  toggleLightDarkMode() {
    this.$store.commit("lightDarkMode", this.$store.state.lightDarkMode === "light" ? "dark" : "light");
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
  }
}
