



















import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase/app";
import "firebase/auth";

@Component
export default class Home extends Vue {
  completeDialog = false;
  email: string = "";

  async submitReset() {
    var auth = firebase.auth();
    try {
      await auth.sendPasswordResetEmail(this.email);
    } catch (error) {}
    this.completeDialog = true;
  }
}
