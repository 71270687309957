import Vue from "vue";
import Vuex, { ActionContext } from "vuex";

import * as packageJson from "../../package.json";

Vue.use(Vuex);

type EnvType = "production" | "staging" | "testing" | "undetermined";

let productionUrl: string = "https://password.gigalot.co.za";
let stagingUrl: string = "https://password.gigalot.systems";

class State {
  constructor() {
    if (window.origin === productionUrl) {
      console.log("Production environment detected.");
      this.environment = "production";
    } else if (window.origin === stagingUrl) {
      console.log("Staging (cloud) environment detected.");
      this.environment = "staging";
    } else {
      console.log("Testing (local) environment detected.");
      this.environment = "testing";
    }
  }

  environment: EnvType;

  lightDarkMode: "light" | "dark" = "dark";
  version: string = packageJson.version;
}

export default new Vuex.Store<State>({
  state: new State(),
  mutations: {
    lightDarkMode(state: any, payload: "light" | "dark") {
      state.lightDarkMode = payload;
    },
    version(state: any) {
      state.version = packageJson.version;
    }
  },
  actions: {},
  getters: {
    dark(state: any) {
      return () => {
        return state.lightDarkMode === "dark";
      };
    }
  }
});
